import Vue from 'vue'
import VueRouter from 'vue-router'
import productState from '../views/productState/index'

Vue.use(VueRouter)

const routes = [
    {
        path: '/productState',
        name: 'productState',
        component: productState,
    }, {
        path: '/campmanager',
        name: 'campmanager',
        component: () => import('@/views/campManager')
    },{
        path:'/checkin',
        name:'checkin',
        component: ()=>import('@/views/checkIn/index')
    },{
        path: '/shop',
        name: 'shop',
        component: () => import('../views/dataStatistics/shop')
    },{
        path: '/statistical',
        name: 'statistical',
        component: () => import('../views/dataStatistics/statistical')
    },{
        path: '/wandering',
        name: 'wandering',
        component: () => import('../views/dataStatistics/wandering')
    },
    {
        path: '/PlatformManager',
        name: 'PlatformManager',
        component: () => import('@/views/PlatformManager/index')
    },
    {
        path: '/activeState',
        name: 'activeState',
        component: () => import('@/views/activeState/index')
    },
    // 查看房态
    {
        path: '/lookRoomState',
        name: 'lookRoomState',
        component: () => import('@/views/lookRoomState/index')
    },
    {
        path: '/campDetail',
        name: 'campDetail',
        component: () => import('@/views/campDetail/index')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
