import axios from "axios";
// import Vue from "vue";
import {Message} from "element-ui";

const service = axios.create({
    // baseURL: process.env.VUE_APP_BASE_API,
    baseURL: 'https://testapi.yunyouzhihui.com',
    // baseURL: 'https://yyadmin.yunyouzhihui.com',
    // baseURL: 'http://192.168.0.26:8062',
    // baseURL: 'http://testapi.yunyouzhihui.com',
    withCredentials: true,
    timeout: 30000
})
service.interceptors.request.use(config => {
    // let token = JSON.parse(localStorage.getItem('layuiAdmin'))
    // console.log(Vue)
    let token = window.location.search.split('?')[1].split('=')[1].split('&')[0]
    // console.log(token)
    // let token = 'eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjE5NGMzYWYzLWY4OTEtNGUyNS1iM2U1LWMyOGM5MGY1ZTc0MCJ9.bRiDda_ZN5DO80FLnrxKQPiv-J0Fv_drwbXycy6ZojSYLZP4cOtbWeTbEsxw4NAzPR9bmZ3MCbxjpvuC4i1rYA'
    // token = token.Authorization
    // console.log(token, 'token')
    // const token = localStorage.getItem('_token')
    if (token) {
        config.headers['Authorization'] = 'bearer ' + token
        // config.headers['Authorization'] = 'bearer ' + token
        // config.headers['Authorization'] = 'bearer ' + 'eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6ImY5MTllYzA3LWJkNGMtNGY3My1iNjczLWUyNWRmMGJhMDYyNyJ9.oTHxYyR-19RujLcsHr95ZtvdljRjcNt7XdLsg2FpXuhGeYt9RCYYeArUfs3Mvayvoff0kWegVvStTnwP2pQT-A'
    }
    return config
}, error => {
    console.log(error)
    return Promise.reject(error)
})

service.interceptors.response.use(response => {
    const res = response.data
    if (res.ResultCode === 0) {
        return res
    } else {
        Message({
            message: res.ResultInfo || 'Error',
            type: 'error',
            duration: 5 * 1000
        })
    }
}, error => {
    console.log('err' + error)
    Message({
        message: error.message,
        type: 'error',
        duration: 5 * 1000
    })
    return Promise.reject(error)
})
export default service


