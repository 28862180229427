import request from "../request/request";

export function listAll(data) {
    return request({
        url: '/yylb/dynamicsMgr/listAll',
        // url: '/yylb/bk/dynamicListAll',
        method: 'POST',
        data
    })
}

export  function productList() {
    return request({
        url: '/yylb/productMgr/productList',
        // url: '/yylb/bk/productList',
        method: 'POST'
    })
}

// 修改编辑
export function saveOrUpdateCalendarRoom(data) {
    return request({
        url: '/yylb/dynamicsMgr/saveOrUpdateCalendarRoom',
        // url: '/yylb/bk/saveOrUpdateCalendarRoom',
        method: 'POST',
        data
    })
}

// 批量修改日历房房态
export function batchEditCalendarRoom(data) {
    return request({
        url: '/yylb/dynamicsMgr/batchEditCalendarRoom',
        // url: '/yylb/bk/batchEditCalendarRoom',
        method: 'POST',
        data
    })
}

// 批量修改活动状态
export function batchEditActivity(data) {
    return request({
        url: '/yylb/dynamicsMgr/batchEditActivity',
        // url: '/yylb/bk/batchEditActivity',
        method: 'POST',
        data
    })
}
// 修改或修改活动状态
export function saveOrUpdateActivity(data) {
    return request({
        url: '/yylb/dynamicsMgr/saveOrUpdateActivity',
        // url: '/yylb/bk/saveOrUpdateActivity',
        method: 'POST',
        data
    })
}
