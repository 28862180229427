<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  mounted() {
    // let token = "eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjgxNTFjNjc3LTIzNWQtNGZiYi1hZDdiLTA1Yjk1MDAzOWVmYiJ9.ydP1WZ45dz9Zp87lh-6YwQkzvtofCSa9VhGCUz249-9P37X3R2d4ps4PLBzfZCi2YddOLRGArqLduXjJB0AqDg"
    // // let token = "eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6ImRmOGM1NDk5LWJiNWItNGFhZS05YTgwLTFhOTlkYzA3Yjk4MSJ9.HAjYg36myp5ToRcjZiGgW_sMfaHy_XfA08OK_PHmY29WLvsLXJJQyJsEgR8kMPNswF1H-m7NseX38EqnnrZgXg"
    // localStorage.setItem('_token', token)
  }
}
</script>
<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[ type= "number" ] {
  -moz-appearance: textfield;
}
* {
  margin: 0;
  padding: 0;
}
</style>
