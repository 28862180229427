<template>
  <div class="index">
    <div class="index_top">
      <div class="index_top_left">
        <span style="margin-right: 11px;">产品名称:</span>
        <el-select v-model="productName" placeholder="请选择产品名称" @change="NameChange">
          <el-option
              v-for="item in opthios"
              :key="item.pid"
              :label="item.name"
              :value="item.pid"
          />
        </el-select>
      </div>
      <div style="margin-left: 96px;">
        <span style="margin-right: 18px;">时间选择</span>
        <el-date-picker
            v-model="calendar"
            type="month"
            placeholder="选择月"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            @change="pickerC"
        >
        </el-date-picker>
      </div>
      <div style="margin-left: 96px;">
        <el-button type="success" style="background: rgb(0,150,136);border-color: rgb(0,150,136);" @click="seach">搜索</el-button>
      </div>
      <div style="margin-left: 22px">
        <el-button type="success" style="background: rgb(0,150,136);border-color: rgb(0,150,136);" @click="cz">重置</el-button>
      </div>
    </div>
    <!--日历房-->
    <div v-if="type === 0" class="index_bottom">
      <div class="index_bottom_top">
        <span style="margin-right: 50px;">{{ productNameText }}</span>
        <el-button type="primary" style="background: rgb(0,150,136);border-color: rgb(0,150,136);" @click="BulkEditingShow = true">批量修改</el-button>
      </div>
      <el-calendar :value="calendar2">
        <!-- 这里使用的是 2.5 slot 语法，对于新项目请使用 2.6 slot 语法-->
        <template slot="dateCell" slot-scope="{date, data}">
          <div style="height: 100%;" @click="selectDate(data.day)">
            <span>{{ data.day.split('-')[2] }}</span>
            <div v-for="(item, index) in list" :key="index" style="text-align: right;">
              <p v-if="item.roomData === data.day" style="color: #348DF0;font-weight: bold;">¥{{ item.price }}</p>
              <p v-if="item.roomData === data.day">剩余<span style="color: #FF7E00;">
                <!--剩余库存-->          <!--房间数量-->
                {{ item.rentedNum }}/{{ item.roomNum }}</span>间</p>
            </div>
          </div>
        </template>
      </el-calendar>
      <!-- 修改单个价格 -->
      <el-dialog title="修改房态" custom-class="dia" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
        <div class="changeRommState_box_top">
          <span style="font-size: 18px;font-family: PingFang SC;font-weight: 500;color: #333333;">日期:</span>
          <span style="margin-left: 28px;font-size: 16px;font-family: PingFang SC;font-weight: 400;color: #666666;">{{ today }}</span>
        </div>
        <div class="changeRommState_box_price">
          <span class="changeRommState_box_price_Text">价格:</span>
          <!--<input v-if="isShow" v-model="selectInfo.price" type="text">-->
          <el-input v-if="isShow" v-model="selectInfo.price" type="number" @input="Gest1" style="width: 40%;" placeholder="请输入价格"></el-input>
          <el-input v-else v-model="price" type="number" @input="Gest2" style="width: 40%;" placeholder="请输入价格"></el-input>
          <!--<input v-else v-model="price" type="text">-->
          <span style="margin-left: 10px;">元</span>
        </div>
        <div style="text-align: center;margin-top: 30px;">
          <el-button type="primary" plain @click="calDia">取消</el-button>
          <el-button type="primary" @click="save">保存</el-button>
        </div>
      </el-dialog>
      <!--批量修改-->
      <el-dialog title="批量修改房态" :visible.sync="BulkEditingShow" width="50%" :before-close="handleClose2" class="dial">
        <el-form label-width="120px">
          <el-form-item label="选择修改日期">
            <el-date-picker
                v-model="BulKEditDate"
                type="daterange"
                range-separator="至"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="日期类型">
            <el-select v-model="BUlkDateType" style="width: 40%" placeholder="请选择">
              <el-option
                  v-for="item in BUlkDateTypeList"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
              >
              </el-option>
            </el-select>
            <div v-if="BUlkDateType === '0'" style="display: flex;justify-content: space-between;margin: 10px 0;">
              <div v-for="(item, index) in weekList" :key="index">
                <el-checkbox v-model="item.checked"></el-checkbox>
                <span>{{ item.name }}</span>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="价格">
            <el-input v-model="BUlkPrice" placeholder="请输入价格" @input="BulkPriceChange" type="number" style="width: 40%;"></el-input>
            <span style="margin-left: 5px;">元</span>
          </el-form-item>
        </el-form>
        <!--<div class="Bulk_box_top">-->
        <!--  <span class="Bulk_box_top_text">选择修改日期</span>-->
        <!--  <el-date-picker-->
        <!--      v-model="BulKEditDate"-->
        <!--      type="daterange"-->
        <!--      range-separator="至"-->
        <!--      format="yyyy-MM-dd"-->
        <!--      value-format="yyyy-MM-dd"-->
        <!--      start-placeholder="开始日期"-->
        <!--      end-placeholder="结束日期">-->
        <!--  </el-date-picker>-->
        <!--</div>-->
        <!--<div class="Bulk_box_Date">-->
        <!--  <span class="Bulk_box_Date_text">日期类型</span>-->
        <!--  <el-select v-model="BUlkDateType" style="width: 40%" placeholder="请选择">-->
        <!--    <el-option-->
        <!--        v-for="item in BUlkDateTypeList"-->
        <!--        :key="item.value"-->
        <!--        :label="item.name"-->
        <!--        :value="item.value"-->
        <!--    >-->
        <!--    </el-option>-->
        <!--  </el-select>-->
        <!--</div>-->
        <!--<div v-if="BUlkDateType === '0'" style="display: flex;justify-content: space-between;margin: 10px 0;">-->
        <!--  <div v-for="(item, index) in weekList" :key="index">-->
        <!--    <el-checkbox v-model="item.checked"></el-checkbox>-->
        <!--    <span>{{ item.name }}</span>-->
        <!--  </div>-->
        <!--</div>-->
        <!--<div class="Bulk_box_price">-->
        <!--  <span class="Bulk_box_price_text">价格</span>-->
        <!--  &lt;!&ndash;<input v-model="BUlkPrice" type="text">&ndash;&gt;-->
        <!--  <el-input v-model="BUlkPrice" @input="BulkPriceChange" type="number" style="width: 40%;"></el-input>-->
        <!--  <span style="margin-left: 5px;">元</span>-->
        <!--</div>-->
        <div style="text-align: center;margin-top: 30px;">
          <el-button type="primary" plain @click="BulkCanel">取消</el-button>
          <el-button type="primary" @click="BulkSave">保存</el-button>
        </div>
      </el-dialog>
    </div>
    <!--活动-->
    <div v-if="type === 1" class="index_bottom">
      <div class="index_bottom_top">
        <span style="margin-right: 50px;">{{ productNameText }}</span>
        <el-button type="primary" style="background: rgb(0,150,136);border-color: rgb(0,150,136);" @click="activeChangeShow = true">批量修改</el-button>
      </div>
      <el-calendar :value="calendar2">
        <template slot="dateCell" slot-scope="{date, data}">
          <div :style="SetBg(data.day) ? 'color:#fff;height:100%;background:rgb(0,150,136)':'height:100%;'" @click.stop="amendActive(data.day)">
            <span>{{ data.day.split('-')[2] }}</span>
            <div v-for="(item, index) in list" :key="index" style="text-align: right;">
              <p v-if="item.roomData === data.day" style="color: #fff;font-weight: bold;">¥{{ item.price }}</p>
              <p v-if="item.roomData === data.day && associate === 1" style="color: #000;">剩余<span style="color: #FF7E00;">{{ item.rentedNum }} <span>/</span> {{ item.roomNum }}</span>间</p>
            </div>
          </div>
        </template>
      </el-calendar>
    </div>
    <!--活动批量修改-->
    <el-dialog title="活动批量修改" :visible.sync="activeChangeShow" width="50%" :before-close="activehandleClose">
      <el-form label-width="160px">
        <el-form-item label="选择修改日期">
            <el-date-picker
                v-model="activeBulkTime"
                type="daterange"
                range-separator="至"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="width: 80%;"
            >
            </el-date-picker>
        </el-form-item>
        <el-form-item label="日期类型">
          <el-select v-model="activeDateType" placeholder="请选择日期类型" style="width: 40%;">
            <el-option
                v-for="item in activeDateTypeList"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
          <div v-if="activeDateType === '0'" style="display: flex;justify-content: space-between;padding-right: 100px;flex-wrap: wrap;">
            <div v-for="(item, index) in activeWeekList" :key="index">
              <el-checkbox v-model="item.checked"></el-checkbox>
              <span>{{ item.name }}</span>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="价格">
          <el-input v-model="activeBulkPrice" @input="activeBulkSet" placeholder="请输入价格" type="number" style="width: 40%"></el-input>
          <span style="margin-left: 10px;">元</span>
        </el-form-item>
        <el-form-item v-if="associate === 1" label="库存类型">
          <el-select v-model="StockType" placeholder="请选择库存类型" style="width: 40%;">
            <el-option
                v-for="item in StockTypeList"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
          <div v-if="StockType === '1'">
            Tips:所选时间段内总库存,每日销售都会扣减库存
          </div>
          <div v-if="StockType === '0'">
            Tips:每日库存单独计算，前一日销售不影响次日库存
          </div>
        </el-form-item>
        <el-form-item v-if="associate === 1" label="库存">
          <el-input type="number" placeholder="请输入库存" v-model="activeBulkStock" style="width: 40%"></el-input>
        </el-form-item>
      </el-form>
      <div style="text-align: center;">
        <el-button plain @click="activeBulkcalen">取消</el-button>
        <el-button type="primary" style="background: rgb(0,150,136);border-color: rgb(0,150,136);" @click="activeBulkSave">保存</el-button>
      </div>
    </el-dialog>
    <!--活动单个日期修改动态-->
    <el-dialog title="修改房态" :visible.sync="activeOShow" width="50%" :before-close="activehandleCloseO">
      <el-form label-width="80px">
        <el-form-item label="日期">
          {{ activeOdate }}
        </el-form-item>
        <el-form-item label="价格">
          <el-input v-if="activeOinfoShow" v-model="activeOinfo.price" placeholder="请输入价格" @input="activeOinfoPriceChange" type="number" style="width: 40%"></el-input>
          <el-input v-else v-model="activeOprice" @input="activeOpriceChange" placeholder="请输入价格" type="number" style="width: 40%"></el-input>
          <span style="margin-left: 10px;">元</span>
        </el-form-item>
        <el-form-item v-if="associate === 1" label="库存">
          <el-input v-model="activeOnum" placeholder="请输入库存" type="number" style="width: 40%;"></el-input>
        </el-form-item>
      </el-form>
      <div style="text-align: center;">
        <el-button type="primary" plain @click="activeOCanel">取消</el-button>
        <el-button type="primary" @click="activeOSave()">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { listAll, productList, saveOrUpdateCalendarRoom, batchEditCalendarRoom, batchEditActivity, saveOrUpdateActivity } from "../../api/productState";
export default {
  name: "index",
  data() {
    return {
      name: '',
      time: '',
      calendar: new Date(),
      weekList: [
        {
          name: '周一',
          checked: false
        },
        {
          name: '周二',
          checked: false
        },
        {
          name: '周三',
          checked: false
        },
        {
          name: '周四',
          checked: false
        },
        {
          name: '周五',
          checked: false
        },
        {
          name: '周六',
          checked: false
        },
        {
          name: '周日',
          checked: false
        }
      ],
      dialogVisible: false,
      list: [],
      opthios: [],
      productName: '',
      selectInfo: {},
      today: '',
      isShow: false,
      price: null,
      productNameText: '',
      calendar2: null,
      BulkEditingShow: false,
      BulKEditDate: new Date(),
      BUlkDateType: '',
      BUlkDateTypeList: [
        {
          name: '自定义',
          value: '0'
        },
        {
          name: '周末(周六丶周日)',
          value: '1'
        },
        {
          name: '工作日(周一至周五)',
          value: '2'
        }
      ],
      BUlkPrice: null,
      type: null,
      activeChangeShow: false,
      beginDate: '',
      endDate: '',
      AlldateList: [],
      activeBulkTime: '',
      StockTypeList: [
        {
          name: '总计库存',
          value: '1'
        },
        {
          name: '每日库存',
          value: '0'
        }
      ],
      StockType: '',
      activeBulkPrice: null,
      activeBulkStock: null,
      // 活动单个日期修改
      activeOShow: false,
      activeOdate: '',
      activeOinfoShow: false,
      activeOinfo: {},
      activeOprice: null,
      activeOnum: null,
      associate: null,
      // 活动日期类型
      activeDateType: '',
      activeDateTypeList: [
        {
          name: '自定义',
          value: '0'
        },
        {
          name: '周末(周六丶周日)',
          value: '1'
        },
        {
          name: '工作日(周一至周五)',
          value: '2'
        }
      ],
      activeWeekList: [
        {
          name: '周一',
          checked: false
        },
        {
          name: '周二',
          checked: false
        },
        {
          name: '周三',
          checked: false
        },
        {
          name: '周四',
          checked: false
        },
        {
          name: '周五',
          checked: false
        },
        {
          name: '周六',
          checked: false
        },
        {
          name: '周日',
          checked: false
        }
      ]
    }
  },
  mounted() {
    // this.getList()
    // console.log(this.$route.query)
    // console.log(this.$route.query.pid, '98789')
    if (this.$route.query.pid !== undefined) {
      this.productName = this.$route.query.pid
    }
    if (this.$route.query.type !== undefined) {
      this.type = this.$route.query.type - 0
    }
    if (this.$route.query.name !== undefined) {
      this.productNameText = this.$route.query.name
    }
    console.log(this.type)
    this.getProduct()
  },
  methods: {
    Gest1(value) {
      // console.log('日历房存在的日期的价格,',value)
      setTimeout(() => {
        this.selectInfo.price = this.strFormatNum(value)
      }, 30)
    },
    Gest2(value) {
      // console.log('日历房不存在的日期的价格', value)
      setTimeout(() => {
        this.price = this.strFormatNum(value)
      }, 30)
    },
    activeBulkSet(value) {
      console.log('活动批量修改的价格', value)
      setTimeout(() => {
        this.activeBulkPrice = this.strFormatNum(value)
      },30)
    },
    activeOinfoPriceChange(value) {
      setTimeout(() => {
        this.activeOinfo.price = this.strFormatNum(value)
      },30)
    },
    activeOpriceChange(value) {
      setTimeout(() => {
        this.activeOprice = this.strFormatNum(value)
      },30)
    },
    BulkPriceChange(value) {
      console.log('日历房批量修改价格', value)
      setTimeout(() => {
        this.BUlkPrice = this.strFormatNum(value)
      },30)
    },
    strFormatNum(val) {
      //清除“数字”和“.”以外的字符
      val = val.replace(/[^\d.]/g, '')
      //只保留第一个. 清除多余的
      val = val.replace(/\.{2,}/g, '.')
      val = val.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
      //只能输入两个小数
      // eslint-disable-next-line
      val = val.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
      // eslint-disable-next-line
      if (val.indexOf('.') < 0 && val != '') {
        //如果没有小数点，首位不能为类似于 01、02的金额
        val = parseFloat(val)
      }
      return val
    },
    // 设置活动区域样式
    SetBg(day) {
      // console.log(this.AlldateList)
      let falg = this.AlldateList.some(item => item === day)
      // console.log(falg)
      return falg
    },
    // 活动批量修改弹框关闭
    activehandleClose() {
      this.activeChangeShow = false
    },
    async getProduct() {
      const res = await productList()
      if (res.ResultCode === 0) {
        if (res.Data.length !== 0) {
          // console.log(this.$route.query.pid, '1')
          // console.log(this.$route.query.name, '2')
          // console.log(this.$route.query.type, '3')
          if (this.$route.query.pid === undefined || this.$route.query.pid === 'undefined') {
            this.productName = res.Data[0].pid
          }
          if (this.$route.query.name === undefined || this.$route.query.name === 'undefined') {
            this.productNameText = res.Data[0].name
          }
          if (this.$route.query.type === undefined || this.$route.query.name === 'undefined') {
            this.type = res.Data[0].type
          }
          this.opthios = res.Data
          this.getList()
        }
      }
    },
    async getList() {
      // const parms = new FormData()
      // parms.append('type',0)
      // parms.append('productId',this.productName)
      const parms = {
        type: this.type,
        productId: this.productName,
        roomData: this.calendar2
      }
      const res = await listAll(parms)
      if (res.ResultCode === 0) {
        this.list = res.Data.list
        if (res.Data.beginDate !== null && res.Data.beginDate !== undefined && res.Data.beginDate !== '') {
          this.beginDate = res.Data.beginDate.split('T')[0]
          // console.log(this.beginDate, '活动开始时间')
        }
        if (res.Data.endDate !== null && res.Data.endDate !== undefined && res.Data.endDate !== '') {
          this.endDate = res.Data.endDate.split('T')[0]
          // console.log(this.endDate, '活动结束时间')
        }
        if (this.beginDate !== '' && this.endDate !== '') {
          // console.log('时间范围有了')
          const arr = this.formatEveryDay(this.beginDate, this.endDate)
          // console.log(arr)
          this.AlldateList = arr
          // console.log(this.AlldateList.indexOf('2022-04-01'), 'CHA')
        }
        if (res.Data.associate !== null && res.Data.associate !== undefined) {
          this.associate = res.Data.associate
        }
      }
    },
    // 获取活动时间范围内的每一天
    formatEveryDay(start, end) {
      let dateList = [];
      let startTime = this.getDate(start);
      let endTime = this.getDate(end);

      while ((endTime.getTime() - startTime.getTime()) >= 0) {
        let year = startTime.getFullYear();
        let month = startTime.getMonth() + 1 < 10 ? '0' + (startTime.getMonth() + 1) : startTime.getMonth() + 1;
        let day = startTime.getDate().toString().length == 1 ? "0" + startTime.getDate() : startTime.getDate();
        dateList.push(year + "-" + month + "-" + day);
        startTime.setDate(startTime.getDate() + 1);
      }
      return dateList;
    },
    getDate(datestr) {
      let temp = datestr.split("-");
      let date = new Date(temp[0], temp[1] - 1, temp[2]);
      return date;
    },
    selectDate(day) {
      this.today = day
      for(let i=0;i<this.list.length;i++) {
        if (this.list[i].roomData === day) {
          this.selectInfo = this.list[i]
          this.isShow = true
        }
      }
      this.dialogVisible = true
    },
    calDia() {
      this.dialogVisible = false
      this.isShow = false
      this.price = null
      this.selectInfo = {}
      this.getList()
    },
    // 修改价格保存
    async save() {
      if (this.isShow) {
        const parms = {
          price: this.selectInfo.price,
          productId: this.productName,
          type: 0,
          pid: this.selectInfo.pid,
          roomData: this.selectInfo.roomData
        }
        const res = await saveOrUpdateCalendarRoom(parms)
        if (res.ResultCode === 0) {
          this.dialogVisible = false
          this.isShow = false
          this.selectInfo = {}
          this.$message('保存成功');
          this.getList()
        }
      } else {
        const parms = {
          price: this.price -0,
          type: 0,
          productId: this.productName,
          roomData: this.today
        }
        const res = await saveOrUpdateCalendarRoom(parms)
        if (res.ResultCode === 0) {
          this.dialogVisible = false
          this.isShow = false
          this.price = ''
          this.getList()
        }
      }
    },
    handleClose() {
      this.isShow = false
      this.selectInfo = {}
      this.dialogVisible = false
      this.getList()
    },
    handleClose2() {
      this.BulkEditingShow = false
      this.weekList.forEach(item => {
        item.checked = false
      })
      this.BUlkDateType = ''
      this.BUlkPrice = null
      this.BulKEditDate = new Date()
    },
    NameChange(e) {
      console.log(e)
      // this.getList()
    },
    seach() {
      this.opthios.forEach(item => {
        if (this.productName === item.pid) {
          this.productNameText = item.name
          this.type = item.type
        }
      })
      this.calendar2 = this.calendar
      this.getList()
    },
    cz() {
      this.opthios.forEach((item, index) => {
        if (index === 0) {
          this.productName = item.pid
          this.productNameText = item.name
        }
      })
      let date = new Date()
      let month  = `${date.getMonth() + 1}`
      let month2 = month.length === 1 ? '0' + month : month
      this.calendar2 = `${date.getFullYear()}-${month2}-01`
      this.calendar = new Date()
      this.list = []
      this.getList()
    },
    pickerC(e) {
      console.log(e)
    },
    BulkCanel() {
      this.BulkEditingShow = false
      this.weekList.forEach(item => {
        item.checked = false
      })
      this.BUlkDateType = null
      this.BulKEditDate = new Date()
    },
    // 批量修改
    async BulkSave() {
      this.BulkEditingShow = false
      const starTime = this.BulKEditDate[0]
      const endTime = this.BulKEditDate[this.BulKEditDate.length -1]
      if (this.BUlkDateType === '0') {
        // 自定义
        let arr = []
        for(let i=0;i<this.weekList.length;i++){
          if (this.weekList[i].checked) {
            arr.push(this.weekList[i].name)
          }
        }
        if (arr.length !== 0) {
          const parms = {
            dateType: 0,
            price: this.BUlkPrice,
            productId: this.productName,
            beginDate: starTime,
            endDate: endTime,
            weeks: arr
          }
          const res = await batchEditCalendarRoom(parms)
          if (res.ResultCode === 0) {
            this.$message({
              type: 'success',
              message: '批量修改成功'
            })
            this.BUlkPrice = null
            this.weekList.forEach(item => {
              item.checked = false
            })
            this.BulKEditDate = new Date()
            this.getList()
          }
        } else {
          this.$message({
            type: 'error',
            message: '自定义时间至少选择一个日期'
          })
        }
      } else {
        // 非自定义
        const parms = {
          price: this.BUlkPrice,
          productId: this.productName,
          beginDate: starTime,
          endDate: endTime,
          dateType: this.BUlkDateType
        }
        const res = await batchEditCalendarRoom(parms)
        if (res.ResultCode === 0) {
          this.$message({
            type: 'success',
            message: '批量修改成功'
          })
          this.BUlkPrice = null
          this.BUlkDateType = ''
          this.BulKEditDate = new Date()
          this.getList()
        }
      }
    },
    // 活动批量修改动态
    async activeBulkSave() {
      this.activeChangeShow = false
      console.log(this.activeBulkTime)
      const startTime = this.activeBulkTime[0]
      const endTime = this.activeBulkTime[this.activeBulkTime.length-1]
      console.log(startTime, endTime)
      const startN = new Date(startTime) // 选择的开始时间
      const starTN2 = new Date(this.beginDate) // 活动的开始时间
      const endN = new Date(endTime) // 选择的结束时间
      const endN2 = new Date(this.endDate) // 活动结束时间
      if (this.associate === 1) {
        if (startN > starTN2 && endN < endN2) {
          console.log('选择的活动日期在范围内')
          if (this.activeBulkPrice === null) {
            this.$message({
              type: 'error',
              message: '价格不能为空'
            })
          } else if (this.StockType === '') {
            this.$message({
              type: 'error',
              message: '请选择库存类型'
            })
          } else if (this.activeBulkStock === null) {
            this.$message({
              type: 'error',
              message: '请输入库存'
            })
          } else {
            console.log('调起接口')
            this.activeSubmit(startTime, endTime)
          }
        } else if (startTime === this.beginDate && endTime === this.endDate) {
          // 活动开始结束日期等于选择的开始日期和结束日期
          if (this.activeBulkPrice === null) {
            this.$message({
              type: 'error',
              message: '价格不能为空'
            })
          } else if (this.StockType === '') {
            this.$message({
              type: 'error',
              message: '请选择库存类型'
            })
          } else if (this.activeBulkStock === null) {
            this.$message({
              type: 'error',
              message: '请输入库存'
            })
          } else {
            console.log('调起接口')
            this.activeSubmit(startTime, endTime)
          }
        } else if (startTime === this.beginDate && endN < endN2) {
          // 活动开始日期等于活动开始日期并小于结束日期
          if (this.activeBulkPrice === null) {
            this.$message({
              type: 'error',
              message: '价格不能为空'
            })
          } else if (this.StockType === '') {
            this.$message({
              type: 'error',
              message: '请选择库存类型'
            })
          } else if (this.activeBulkStock === null) {
            this.$message({
              type: 'error',
              message: '请输入库存'
            })
          } else {
            console.log('调起接口')
            this.activeSubmit(startTime, endTime)
          }
        } else if (this.endDate === endTime && startN > starTN2) {
          // 活动结束日期等于选择的结束日期且活动开始日期大于选择的开始日期
          if (this.activeBulkPrice === null) {
            this.$message({
              type: 'error',
              message: '价格不能为空'
            })
          } else if (this.StockType === '') {
            this.$message({
              type: 'error',
              message: '请选择库存类型'
            })
          } else if (this.activeBulkStock === null) {
            this.$message({
              type: 'error',
              message: '请输入库存'
            })
          } else {
            console.log('调起接口')
            this.activeSubmit(startTime, endTime)
          }
        } else {
          this.$message({
            type: 'error',
            message: '选择修改的日期必须在活动范围内'
          })
        }
      } else if (this.associate === 0) {
        if (startN > starTN2 && endN < endN2) {
          console.log('选择的活动日期在范围内')
          if (this.activeBulkPrice === null) {
            this.$message({
              type: 'error',
              message: '价格不能为空'
            })
          } else {
            console.log('调起接口')
            this.activeSubmit(startTime, endTime)
          }
        } else if (startTime === this.beginDate && endTime === this.endDate) {
          // 活动开始结束日期等于选择的开始日期和结束日期
          if (this.activeBulkPrice === null) {
            this.$message({
              type: 'error',
              message: '价格不能为空'
            })
          } else {
            console.log('调起接口')
            this.activeSubmit(startTime, endTime)
          }
        } else if (startTime === this.beginDate && endN < endN2) {
          // 活动开始日期等于活动开始日期并小于结束日期
          if (this.activeBulkPrice === null) {
            this.$message({
              type: 'error',
              message: '价格不能为空'
            })
          }  else {
            console.log('调起接口')
            this.activeSubmit(startTime, endTime)
          }
        } else if (this.endDate === endTime && startN > starTN2) {
          // 活动结束日期等于选择的结束日期且活动开始日期大于选择的开始日期
          if (this.activeBulkPrice === null) {
            this.$message({
              type: 'error',
              message: '价格不能为空'
            })
          }  else {
            console.log('调起接口')
            this.activeSubmit(startTime, endTime)
          }
        } else {
          this.$message({
            type: 'error',
            message: '选择修改的日期必须在活动范围内'
          })
        }
      }
    },
    async activeSubmit(startTime, endTime) {
      console.log(startTime, endTime)
      if (this.activeDateType === '0') {
        // 自定义日期
        let arr = []
        for(let i=0;i<this.activeWeekList.length;i++) {
          if (this.activeWeekList[i].checked) {
            arr.push(this.activeWeekList[i].name)
          }
        }
        if (arr.length !== 0) {
          const parms = {
            beginDate: startTime,
            endDate: endTime,
            price: this.activeBulkPrice - 0,
            productId: this.productName,
            stockType: this.StockType,
            type: this.type,
            rentedNum: this.activeBulkStock -0,
            rentedSum: this.activeBulkStock - 0,
            dateType: this.activeDateType,
            weeks: arr
          }
          console.log(parms)
          const res = await batchEditActivity(parms)
          if (res.ResultCode === 0) {
            this.$message({
              type: 'success',
              message: '修改成功'
            })
            this.activeBulkPrice = null
            this.activeBulkStock = null
            this.StockType = ''
            this.activeDateType = ''
            this.activeBulkTime = ''
            this.getList()
          }
        } else {
          this.$message({
            type: 'error',
            message: '自定义时间至少选择一个日期'
          })
        }
      } else {
        // 非自定义日期
        const parms = {
          beginDate: startTime,
          endDate: endTime,
          price: this.activeBulkPrice - 0,
          productId: this.productName,
          stockType: this.StockType,
          type: this.type,
          rentedNum: this.activeBulkStock -0,
          rentedSum: this.activeBulkStock - 0,
          dateType: this.activeDateType
        }
        console.log(parms)
        const res = await batchEditActivity(parms)
        if (res.ResultCode === 0) {
          this.$message({
            type: 'success',
            message: '修改成功'
          })
          this.activeBulkPrice = null
          this.activeBulkStock = null
          this.activeDateType = ''
          this.StockType = ''
          this.activeBulkTime = ''
          this.getList()
        }
      }
    },
    activeBulkcalen() {
      this.activeBulkPrice = null
      this.activeBulkStock = null
      this.StockType = ''
      this.activeBulkTime = ''
      this.activeChangeShow = false
    },
    // 活动修改单个动态
    amendActive(day) {
      let index = this.AlldateList.indexOf(day)
      console.log(index)
      if (index !== -1) {
        this.activeOShow = true
        this.activeOdate = day
        for(let i=0;i<this.list.length;i++) {
          if (this.list[i].roomData === day) {
            this.activeOinfoShow = true
            this.activeOinfo = this.list[i]
          }
        }
      }
      console.log(day, '日期')
    },
    // 活动单个日期修改事件
    activehandleCloseO() {
      this.activeOShow = false
      this.activeOinfo = {}
      this.activeOinfoShow = false
    },
    // 活动单个日期修改保存事件
    async activeOSave() {
      this.activeOShow = false
      if (this.activeOinfoShow) {
        // 修改
        console.log('修改')
        const parms = {
          type: this.type,
          stockType: 0,
          stockId: this.activeOinfo.stockId,
          roomData: this.activeOdate,
          pid: this.activeOinfo.pid,
          price: this.activeOinfo.price - 0,
          rentedNum: this.activeOnum - 0,
          productId: this.productName
        }
        console.log(parms)
        const res = await saveOrUpdateActivity(parms)
        console.log(res)
        if (res.ResultCode === 0) {
          this.$message({
            type: 'success',
            message: '修改成功'
          })
          this.activeOinfo = {}
          this.activeOinfoShow = false
          this.activeOnum = null
          this.getList()
        }
      } else {
        // 新增
        console.log('保存')
        const parms = {
          type: this.type,
          stockType: 0,
          roomData: this.activeOdate,
          price: this.activeOprice - 0,
          rentedNum: this.activeOnum - 0,
          productId: this.productName
        }
        console.log(parms)
        const res = await saveOrUpdateActivity(parms)
        console.log(res)
        if (res.ResultCode === 0) {
          this.$message({
            type: 'success',
            message: '新增成功'
          })
          this.activeOprice = null
          this.activeOnum = null
          this.getList()
        }
      }
    },
    // 修改单个活动取消按钮
    activeOCanel() {
      this.activeOShow = false
      this.activeOinfoShow = false
      this.activeOinfo = {}
    }
  }
}
</script>

<style scoped>
.index{
  padding-left: 18px;
  padding-top: 20px;
  padding-right: 22px;
  background: #F5F6F7;
}
.index_top{
  padding: 36px 0 36px 45px;
  background: #FFFFFF;
  display: flex;
}
.index_top_left{
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
}
.index_bottom{
  margin-top: 22px;
  background: #fff;
  padding-top: 28px;
  padding-left: 23px;
  padding-right: 23px;
}
.calendar_box{
  margin-top: 18px;
  border: 1px solid #F4F4F4;
}
.calendar_box_top{
  display: flex;
  justify-content: space-between;
}
.calendar_box_top_item{
  border: 1px solid #E8E8E8;
  height: 48px;
  background: #F3F9FF;
  flex: 1;
  text-align: center;
  color: #262C33;
}
>>>.el-button-group{
  display: none;
}
</style>
<style lang="scss" scoped>
.Bulk_box_top{
  margin-bottom: 10px;
  .Bulk_box_top_text{
    margin-right: 30px;
  }
}
.Bulk_box_Date{
  margin-bottom: 10px;
  .Bulk_box_Date_text{
    margin-right: 58px;
  }
}
.Bulk_box_price{
  .Bulk_box_price_text{
    margin-right: 85px;
  }
}
</style>
<style lang="scss" scoped>
.changeRommState_box_top{
  margin-bottom: 10px;
}
.changeRommState_box_price{
  .changeRommState_box_price_Text{
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    margin-right: 30px;
  }
}
</style>
<style lang="scss" scoped>
::v-deep.el-calendar{
  margin-top: 20px;
  .el-calendar__header{
    display: none;
  }
  .el-calendar__body{
    padding: 0;
    thead{
      background: #F3F9FF;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #262C33;
    }
  }
  .el-calendar-table .el-calendar-day {
    //box-sizing: border-box;
    //padding: 8px;
    //height: 85px;
    padding: 0;
  }
}
::v-deep.el-dialog{
  background: #00a0e9;
  margin: 0 !important;
}
</style>
